import React, { useEffect, useState } from "react";
import "./App.scss";

import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./page/Home/Home";
import Products from "./page/Products/Products";
import Product from "./page/Product/Product";

import pgimage from "../src/img/tex.png";
import Contact from "./page/Contact/Contact";
import About from "./page/About/About";
import Conditions from "./page/t&c/Conditions";
import PrivacyPolicy from "./page/Policy/PrivacyPolicy";
import Succsess from "./components/payment/Succsess";
import Failed from "./components/payment/Failed";
import Navbar from "./components/Navbar/Navbar";
import Whatapp from "./components/whatapp/Whatapp";
import Footer from "./components/Footer/Footer";
import Error from "./page/Error/Error";
import Promotion from "./page/promotion/Promotion";
import Modal from "./components/modal/Modal";

// pages for the router

const Layout = () => {
  return (
    <div className="app">
      <Navbar />
      <Whatapp />

      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/products/:id",
        element: <Products />,
      },

      {
        path: "/product/:id",
        element: <Product />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/promotion",
        element: <Promotion />,
      },

      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/terms-&-conditions",
        element: <Conditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/succsess",
        element: <Succsess />,
      },
      {
        path: "/failed",
        element: <Failed />,
      },
      {
        path: "*",
        element: <Error />,
      },
      {
        path: "/mail",
        element: <Error />,
      },
    ],
  },
]);

//main component

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsModalOpen(true);
    }, 2000);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <RouterProvider router={router} />{" "}
      {/* <Modal isOpen={isModalOpen} closeModal={closeModal}></Modal> */}
    </div>
  );
};

export default App;
