import React from "react";
import "./Conditions.scss";

const Conditions = () => {
  return (
    <div className="condition">
      <h1>Terms and conditions</h1>
      <p>
        This Privacy Policy applies to the products offered by flysky.com.
        Sometimes, we may post product specific privacy notices to explain our
        products in more detail. At flysky.com , we are committed to
        protecting the privacy of our customers, which is why we will not sell
        any personal identifiable information to any third party. By visiting
        flysky.com , you are agreeing and accepting to the practices
        described in this Privacy Notice.
      </p>
      <br />
      <br />
      <h2>Information collection & use</h2>
      <p>
        flysky.comcollects personally identifiable information when you
        request information from flysky.com. On such occasions, we will ask
        for your name, email address, zip code and personal information
        concerning your interests, occupation, gender and other demographic
        information. flysky.comalso receives and records information from
        server logs from your browser including cookie information relation to
        pages requested and the like. This helps us better understand what our
        customers would like to see from us. We use this information internally
        regarding what our customers want and mailing of circulars and special
        offers from flysky.com. We do not give or sell Personal Information
        to any other party. If at any time you do not want to receive offers
        and/or circulars from us. You can always remove yourself from our
        mailing list by sending an e-mail to info@flysky.com In addition
        to the above, we may use the information we collect to provide,
        maintain, protect, and improve our services (including advertising
        services) and develop new services. We will also protect the rights or
        property of flysky.comor our users. If we use this information in a
        manner different than the purpose for which it was collected, then we
        will ask for your consent prior to such use.
      </p>
    </div>
  );
};

export default Conditions;
