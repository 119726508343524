import React from "react";
import "./whatapp.css";

const Whatapp = () => {
  return (
    <div>
      <a href="https://wa.me/447577126899" target="_blank">
        <div className="whatapp-icon">
          <span className="what-text"> Need Help ? Chat with us </span>{" "}
          <img src={require("../../img/WhatsApp-Logo.wine.png")} alt="" />
        </div>{" "}
      </a>{" "}
    </div>
  );
};

export default Whatapp;
