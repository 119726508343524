import React from "react";
import "./modal.scss";
import promo from "../../img/promo.png";
import { Link } from "react-router-dom";

const Modal = ({ isOpen, closeModal, children }) => {
  const handleSubmit = (e) => {
      e.preventDefault();
    window.open("https://winatriptodubai.com/Duty-Free/");
  };

  return isOpen ? (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>
          ⓧ
        </button>
        <img src={require("../../img/promo.png")} alt="" />
      </div>
    </div>
  ) : null;
};

export default Modal;
